<template>
    <ModuleWrapper :pageTitle="pageTitle" :headerBg="!isMobile" class="project_list">
        <template v-if="!isMobile" v-slot:h_left>
            <PageFilter 
                :model="model"
                :key="page_name"
                size="large"
                :page_name="page_name" />
        </template>
        <template v-if="!isMobile" v-slot:h_right>
            <a-dropdown>
                <a-button size="large" class="mr-3 flex items-center">
                    Скачать 
                    <i class="fi fi-rr-angle-small-down ml-1"></i>
                </a-button>
                <a-menu slot="overlay">
                    <a-menu-item :disabled="empty" key="download" @click="generateExcel()">
                        <i class="fi fi-rr-download mr-2"></i>Скачать выгрузку 
                    </a-menu-item>
                    <a-menu-item :disabled="empty" key="map" @click="generateExcel('roadmap')">
                        <i class="fi fi-rr-download mr-2"></i>Дорожная карта
                    </a-menu-item>
                </a-menu>
            </a-dropdown>
            <a-button type="primary" size="large" icon="plus" @click="addProject()">
                Добавить проект
            </a-button>
        </template>
        <a-empty v-if="empty" description="Нет проектов" />
        <a-checkbox-group
            v-model="chcGroup"
            name="investgroup">
            <div class="list_grid grid-cols-1 2xl:grid-cols-2">
                <Card 
                    v-for="item in list.results" 
                    :key="item.id" 
                    :item2="item"
                    :item="item" />
            </div>
        </a-checkbox-group>
        <infinite-loading 
            ref="project_infinity"
            @infinite="getList"
            v-bind:distance="10">
            <div 
                slot="spinner"
                class="flex items-center justify-center inf_spinner">
                <a-spin />
            </div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>
        <div v-if="chcGroup.length" class="float_dummy"></div>
        <!--<transition name="button_fade">
            <div v-if="chcGroup.length" class="add_widget_float">
                <a-badge 
                    :number-style="{ backgroundColor: '#52c41a' }"
                    :count="chcGroup.length > 1 ? chcGroup.length : 0">
                    <a-button 
                        flaticon
                        icon="fi-rr-download"
                        size="large">
                        Скачать выгрузку 
                    </a-button>
                </a-badge>
            </div>
        </transition>-->
        <div 
            v-if="isMobile"
            class="float_add">
            <div class="filter_slot">
                <PageFilter 
                    :model="model"
                    :key="page_name"
                    size="large"
                    :page_name="page_name" />
            </div>
            <a-button 
                flaticon
                :disabled="empty"
                shape="circle"
                size="large"
                class="mb-2"
                icon="fi-rr-download"
                @click="activity = true" />
            <a-button 
                flaticon
                shape="circle"
                size="large"
                type="primary"
                icon="fi-rr-plus"
                @click="addProject()" />
        </div>
        <ActivityDrawer 
            v-if="isMobile"
            :vis="activity" 
            useVis
            :cDrawer="closeDrawer">
            <ActivityItem @click="generateExcel()">
                <i class="fi fi-rr-download icon"></i>Скачать выгрузку 
            </ActivityItem>
            <ActivityItem @click="generateExcel('roadmap')">
                <i class="fi fi-rr-download icon"></i>Дорожная карта
            </ActivityItem>
        </ActivityDrawer>
    </ModuleWrapper>
</template>

<script>
import ModuleWrapper from '@/components/ModuleWrapper/index.vue'
import Card from './components/Card.vue'
import eventBus from '@/utils/eventBus'
import InfiniteLoading from 'vue-infinite-loading'
import PageFilter from '@/components/PageFilter'
import { ActivityItem, ActivityDrawer } from '@/components/ActivitySelect'
const messageKey = 'file_loading'
export default {
    components: {
        ModuleWrapper,
        Card,
        InfiniteLoading,
        PageFilter,
        ActivityItem,
        ActivityDrawer
    },
    computed: {
        pageTitle() {
            return this.$route?.meta?.title || ''
        },
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    data() {
        return {
            chcGroup: [],
            activity: false,
            list: {
                results: [],
                next: true,
                count: 0
            },
            page: 0,
            empty: false,
            loading: false,
            page_name: 'invest_project_list',
            model: 'invest_projects_info.InvestProjectInfoModel'
        }
    },
    methods: {
        closeDrawer() {
            this.activity = false
        },
        async generateExcel(file_type = null) {
            try {
                this.$message.loading({ content: 'Генерация файла', key: messageKey })
                const params = {
                    exclude: this.chcGroup.length ? this.chcGroup.join(',') : '',
                    page_name: this.page_name
                }
                if(file_type)
                    params.file_type = file_type
                const { data } = await this.$http.get('/invest_projects_info/file/', {
                    responseType: 'blob',
                    params
                })

                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                const fileName = file_type === 'roadmap' ? 'Дорожная карта' : 'Выгрузка'
                link.setAttribute('download', `${fileName} от ${this.$moment().format('DD.MM.YYYY HH:mm')}.XLSX`)
                document.body.appendChild(link)
                link.click()

                this.$message.success({ content: 'Файл успешно создан', key: messageKey })
                this.chcGroup = []
            } catch(e) {
                console.log(e)
                this.$message.success({ content: 'Ошибка при генерации файла', key: messageKey })
            }
        },
        async getList($state) {
            if(!this.loading && this.list.next) {
                try {
                    this.loading = true
                    this.page += 1
                    const { data } = await this.$http.get('/invest_projects_info/', {
                        params: {
                            page: this.page,
                            page_size: 8,
                            page_name: this.page_name
                        }
                    })

                    if(data) {
                        this.list.count = data.count
                        this.list.next = data.next
                    }

                    if(data?.results?.length)
                        this.list.results = this.list.results.concat(data.results)

                    if(this.page === 1 && !this.list.results.length) {
                        this.empty = true
                    }
                        
                    if(this.list.next)
                        $state.loaded()
                    else
                        $state.complete()
                } catch(e) {
                    console.log(e)
                } finally {
                    this.loading = false
                }
            }
        },
        addProject() {
            eventBus.$emit('add_invest_proejct')
        },
        listReload() {
            this.$nextTick(() => {
                this.chcGroup = []
                this.page = 0
                this.empty = false
                this.list = {
                    results: [],
                    next: true,
                    count: 0
                }
                this.$refs['project_infinity'].stateChanger.reset()
            })
        }
    },
    mounted() {
        eventBus.$on(`update_filter_${this.model}`, () => {
            this.listReload()
        })
        eventBus.$on('update_invest_list', () => {
            this.listReload()
        })
    },
    beforeDestroy() {
        eventBus.$off(`update_filter_${this.model}`)
        eventBus.$off('update_invest_list')
    }
}
</script>

<style lang="scss" scoped>
.project_list{
    .button_fade-enter-active, .button_fade-leave-active {
        transition: all 0.3s;
    }
    .button_fade-enter, .button_fade-leave-to {
        opacity: 0;
        transform: translateY(30px);
    }
}
.float_dummy{
    min-height: 60px;
}
.add_widget_float{
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    position: fixed;
    bottom: calc(65px + var(--safe-area-inset-bottom));
    left: 50%;
    z-index: 50;
    display: flex;
    flex-direction: column;
    margin-left: -100px;
    @media (min-width: 768px) {
        bottom: calc(15px + var(--safe-area-inset-bottom));
    }
    &::v-deep{
        .ant-btn{
            border-radius: 30px;
            padding-left: 20px;
            padding-right: 20px;
            width: 200px;
            border-color: #000000;
            color: #000000;
            -webkit-backdrop-filter: saturate(180%) blur(20px);
            backdrop-filter: saturate(180%) blur(20px);
            background: rgba(251, 251, 253, 0.8);
        }
    }
}
.list_grid{
    display: grid;
    gap: 15px;
    @media (min-width: 1700px) {
        gap: 30px;
    }
}
</style>